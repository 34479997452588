import * as React from "react";
import { Seo, Header } from "components";
import styled from "styled-components";
import {
  colors,
  FullWidthContent,
  Section,
  Container,
} from "styles";
import { graphql } from "gatsby";

const TermsConditionsPage = ({ data }) => {
  const { title, terms } = data.markdownRemark.frontmatter;
  return (
    <>
      <Seo title={title} />
      <Header data={data} title={title} />
      <Section>
        <Container style={{ gridGap: ".25rem" }}>
          <FullWidthContent style={{ alignItems: 'flex-start' }}>
            {terms.map((term) => (
              <Term $colors={colors}>
                <h3 dangerouslySetInnerHTML={{ __html: term.title }} />
                <p dangerouslySetInnerHTML={{ __html: term.text }} />
              </Term>
            ))}
          </FullWidthContent>
        </Container>
      </Section>
    </>
  );
};

const Term = styled.div`
  margin-bottom: 3rem;
  h3 {
    margin-bottom: 0.5rem;
    color: ${colors.red};
  }
`;

export default TermsConditionsPage;

export const query = graphql`
  query TermsConditionsQuery {
    markdownRemark(frontmatter: { title: { eq: "Terms & Conditions" } }) {
      frontmatter {
        title
        terms {
          title
          text
        }
      }
    }
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;